<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CCard>
			<CCardBody>
				<TheTableHeader
					:header="'FORMULIR PENGATURAN'"
					:subHeader="'DATA CENTER DI LINTASARTA.'"
				/>
			</CCardBody>
		</CCard>
		<CRow>
			<CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>Form {{id > 0 ? 'Perubahan' : 'Penambahan'}}</strong> Data Center.
					</CCardHeader>
					<CCardBody>
						<CForm>
							<CInput
								type="text"
								description=""
								label="ID Data Center"
								v-model="id"
								plaintext
								readonly
							/>
							<CInput
								type="text"
								description=""
								label="Nama Data Center"
								placeholder="Masukkan nama data center.."
								v-model="name"
								required
							/>
							<CInput
								type="email"
								description=""
								label="Email Support"
								placeholder="Masukkan alamat email helpdesk.."
								v-model="email"
								required
							/>
							<CInput
								type="number"
								description=""
								label="Telpon Support Helpdesk"
								placeholder="Masukkan no telp helpdesk.."
								v-model="phone"
								required
							/>
							<CTextarea
								rows="3"
								description=""
								label="Alamat"
								placeholder="Masukkan alamat data center.."
								v-model="address"
								required
							/>
						</CForm>
					</CCardBody>
					<CCardFooter>
						<CButton type="submit" size="sm" color="success" @click="id === '0' ? createSite() : updateSite()"><CIcon name="cil-check-circle"/> Save</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger" @click="resetForm()"><CIcon name="cil-ban"/> Clear</CButton> &nbsp;&nbsp;
						<CButton type="cancel" size="sm" color="primary" @click="goBack()"><CIcon name="cil-ban"/> Cancel</CButton>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'SiteManagement',
	components: { TheTableHeader },
	data () {
		return {
			id: this.$route.params.id,
			name: '',
			email: '',
			phone: '',
			address: '',
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Data Center',
          href: '#/settings/sites'
        }, 
        {
          text: 'Manajemen Data Center'
        }
      ],
		}
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goBack(){
      this.$router.go(-1);
    },
		createSite() {
			let self = this;
			return http.post('/sites', {
				site_name: self.name,
				email: self.email,
				phone: self.phone,
				address: self.address
			})
			.then(function (response) {
				self.toast('Data Center Berhasil Ditambahkan.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		updateSite() {
			let self = this;
			return http.put('/sites/' + self.id, {
				site_name: self.name,
				email: self.email,
				phone: self.phone,
				address: self.address
			})
			.then(function (response) {
				self.toast('Data Center Berhasil Diubah.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		resetForm() {
			let self = this;
			self.name = '';
			self.email = '';
			self.phone = '';
			self.address = '';
		},
		getSite(id) {
			let self = this;
			if (id !== '0') {
				return http.get('/sites/' + id)
				.then(function (response) {
					self.name = response.data.data.site_name;
					self.email = response.data.data.email;
					self.phone = response.data.data.phone;
					self.address = response.data.data.address;
				}).catch(function (error) {
					console.log(error);
				});
			}
		}
	},
	mounted: function(){
		this.getSite(this.id);
	}
}
</script>